import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  TextField,
  makeStyles,
  Button
} from '@material-ui/core';
import moment from 'moment';
import { baseUrl, authHeader } from 'src/utils/helper';
import axios from 'axios';
import GetAppIcon from '@material-ui/icons/GetApp';

const PayoutCSVFile = () => {
  const [from, setFromDate] = useState('');
  const currentDate = moment().format('YYYY-MM-DD');

  const styles = {
    container: {
      marginTop: 24
    },
    wrapper: {
      margin: '0 auto',
      backgroundColor: 'white',
      borderRadius: '8px',
      boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
      padding: '24px'
    },
    title: {
      fontWeight: 500,
      color: '#3a3a3a',
      marginBottom: '16px',
    },
    label: {
      fontSize: '14px',
      fontWeight: 500,
      color: '#3a3a3a',
    },
    inputGroup: {
      marginBottom: '16px',
      marginTop: '4px'
    },
    dateInput: {
      width: '200px',
      padding: '8px',
      border: '1px solid #ddd',
      borderRadius: '4px',
      marginBottom: '16px'
    },
    buttonContainer: {
      display: 'flex',
      gap: '16px'
    },
    button: {
      maxWidth: "300px",
      flex: 1,
      padding: '12px',
      backgroundColor: '#2563eb',
      color: 'white',
      border: 'none',
      borderRadius: '4px',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '8px',
      fontSize: '14px'
    },
    buttonDisabled: {
      backgroundColor: '#cdcdcd',
      cursor: 'not-allowed'
    },
    icon: {
      fontSize: 20
    }
  };

  const handleDownload = (url, fileName) => {
    axios({
      url: url,
      method: 'GET',
      responseType: 'blob',
      headers: authHeader().headers,
    })
      .then(response => {
        const fileURL = URL.createObjectURL(response.data);
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = fileName;
        link.click();
        URL.revokeObjectURL(fileURL);
      })
      .catch(error => console.error('Error:', error));
  };

  return (
    <div style={styles.container}>
      <div style={styles.wrapper}>
        <h2 style={styles.title}>Files</h2>
        <label style={styles.label}>Auszahlungsdatum</label>
        <div style={styles.inputGroup}>
          <input
            type="date"
            style={styles.dateInput}
            max={currentDate}
            value={from}
            onChange={(e) => setFromDate(e.target.value)}
          />

          <div style={styles.buttonContainer}>
            <button
              disabled={!from}
              onClick={() => {
                const date = moment(from).format('DD/MM/YYYY');
                handleDownload(
                  `${baseUrl}/payouts/payment-csv-file?date=${date}`,
                  `payout_file_${date}.csv`
                );
              }}
              style={{
                ...styles.button,
                ...(from ? {} : styles.buttonDisabled)
              }}
            >
              <GetAppIcon style={styles.icon} />
              Auszahlungs-CSV-Datei herunterladen
            </button>

            <button
              disabled={!from}
              onClick={() => {
                const date = moment(from).format('DD/MM/YYYY');
                handleDownload(
                  `${baseUrl}/payouts/donation-csv-file?date=${date}`,
                  `donation_file_${date}.csv`
                );
              }}
              style={{
                ...styles.button,
                ...(from ? {} : styles.buttonDisabled)
              }}
            >
              <GetAppIcon style={styles.icon} />
              Spenden-CSV-Datei herunterladen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PayoutCSVFile;