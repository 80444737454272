import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getPayoutLogs } from "src/actions/clubActions";
import moment from "moment";
import { Button, Typography, IconButton } from "@material-ui/core";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import CheckIcon from '@material-ui/icons/Check';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import LastPageIcon from '@material-ui/icons/LastPage';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';

const styles = {
  container: {
    marginTop: "24px",
    marginBottom: "24px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    padding: "24px",
    boxShadow: "0 1px 3px rgba(0,0,0,0.12)",
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: "24px",
    gap: "12px",
  },
  title: {
    color: "#3a3a3a",
    margin: 0,
  },
  logEntry: {
    padding: "4px 0px",
    margin: "8px 0",
    display: "flex",
    alignItems: "center",
    gap: "16px",
    borderBottom: "1px solid #f4f4f4"
  },
  icon: {
    padding: "4px",
    borderRadius: "50%",
    backgroundColor: "#b5edb7",
    color: "#4caf50",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  iconCharacter: {
    fontSize: 16,
  },
  pagination: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "8px",
    marginTop: "24px",
  },
  pageButton: {
    minWidth: "40px",
    height: "40px",
    padding: "0",
  },
  activeButton: {
    backgroundColor: "#2196f3",
    color: "#fff",
    "&:hover": {
      backgroundColor: "#1976d2",
    },
  },
};

const ITEMS_PER_PAGE = 10;

const PayoutLogs = ({ getPayoutLogs }) => {
  const [logs, setLogs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
   const fetchLogs = async () => {
     const data = await getPayoutLogs();
     setLogs(data);
   };
   fetchLogs();
  }, [getPayoutLogs]);

  const totalPages = Math.ceil(logs.length / ITEMS_PER_PAGE);
  const startIndex = (currentPage - 1) * ITEMS_PER_PAGE;
  const visibleLogs = logs.slice(startIndex, startIndex + ITEMS_PER_PAGE);

  const handlePageChange = (newPage) => {
   setCurrentPage(newPage);
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <h3 style={styles.title}>Auszahlungsprotokoll</h3>
        <Typography variant="body2" color="textSecondary">
          {logs.length} Einträge insgesamt
        </Typography>
      </div>

      {visibleLogs.map((log, index) => (
        <div key={index} style={styles.logEntry}>
          <div style={styles.icon}>
            <CheckIcon style={styles.iconCharacter}/>
          </div>
          <div>
            <strong>{moment(log.date).format("DD.MM.YYYY")}: </strong>
            {log.amount.toFixed(2)} {" CHF"} {" an "}
            <strong>{log.clubName}</strong>
          </div>
        </div>
      ))}

      {totalPages > 1 && (
        <div style={styles.pagination}>
          <IconButton
            style={styles.paginationButton}
            onClick={() => handlePageChange(1)}
            disabled={currentPage === 1}
          >
            <FirstPageIcon />
          </IconButton>
          <IconButton
            style={styles.paginationButton}
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <NavigateBeforeIcon />
          </IconButton>

          <div style={styles.pageInfo}>
            <Typography variant="body2">
              {`${currentPage} / ${totalPages}`}
            </Typography>
          </div>

          <IconButton
            style={styles.paginationButton}
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <NavigateNextIcon />
          </IconButton>
          <IconButton
            style={styles.paginationButton}
            onClick={() => handlePageChange(totalPages)}
            disabled={currentPage === totalPages}
          >
            <LastPageIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default connect(null, { getPayoutLogs })(PayoutLogs);
