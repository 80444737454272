import React from 'react';
import {
  Document, Page, Text, View, StyleSheet, Image
} from '@react-pdf/renderer';
import PropTypes from 'prop-types';
import moment from 'moment';
import HeaderImage from '../assets/header-new.png';

const formatNumber = (num) => {
  return num.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, "'");
};

const styles = StyleSheet.create({
  page: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    padding: '30px 60px',
  },
  header: {
    marginBottom: 40,
  },
  headerLogoContainer: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginBottom: 30,
  },
  headerImage: {
    width: '100%',
    maxWidth: 400,
  },
  addressBlock: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  address: {
    width: '45%',
  },
  addressTitle: {
    fontSize: 12,
    marginBottom: 8,
  },
  addressText: {
    fontSize: 12,
    lineHeight: 1.4,
  },
  addressRight: {
    width: '45%',
    alignItems: 'flex-end'
  },
  addressTitleRight: {
    fontSize: 12,
    marginBottom: 8,
    textAlign: "right"
  },
  addressTextRight: {
    fontSize: 12,
    lineHeight: 1.4,
    textAlign: "right"
  },
  mainContent: {
    marginTop: 40,
  },
  title: {
    fontSize: 26,
    marginBottom: 10,
  },
  dateRange: {
    fontSize: 12,
    marginBottom: 30,
    paddingBottom: 10,
    borderBottom: '1px solid #000',
  },
  summaryTable: {
    marginBottom: 30,
  },
  tableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    paddingBottom: 8,
    marginBottom: 8,
    fontFamily: 'Helvetica-Bold',
  },
  tableRow: {
    flexDirection: 'row',
    paddingVertical: 6,
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
  },
  col1: { width: '35%' },
  col2: { width: '15%', textAlign: 'right' },
  col3: { width: '25%', textAlign: 'right' },
  col4: { width: '25%', textAlign: 'right' },
  totalLabel: {
    width: '75%',
    textAlign: 'right',
    fontFamily: 'Helvetica-Bold',
    paddingRight: 8,
  },
  totalAmount: {
    width: '25%',
    textAlign: 'right',
    fontFamily: 'Helvetica-Bold',
  },
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
    paddingTop: 10,
    borderTopWidth: 2,
    borderTopStyle: 'double',
    borderTopColor: '#000',
  },
  footerContainer: {
    position: 'absolute',
    bottom: 30,
    left: 60,
    right: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: 10,
    borderTopWidth: 1,
    borderTopColor: "#666"
  },
  footerText: {
    fontSize: 10,
    color: '#666',
    textAlign: 'left',
  },
  footerRight: {
    alignItems: 'flex-end'
  },
  footerTextRight: {
    fontSize: 10,
    color: '#666',
    textAlign: 'right'
  },
  detailPage: {
    fontFamily: 'Helvetica',
    fontSize: 11,
    padding: '30px 60px',
  },
  productTitle: {
    fontSize: 18,
    marginBottom: 20,
    borderBottom: '1px solid #000',
    paddingBottom: 10,
  },
  detailTableHeader: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#000',
    paddingVertical: 8,
    fontFamily: 'Helvetica-Bold',
  },
  detailTableRow: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#eee',
    paddingVertical: 6,
  },
  detailName: { width: '25%' },
  detailId: { width: '15%' },
  detailAmount: { width: '15%', textAlign: 'right' },
  detailCommission: { width: '20%', textAlign: 'right' },
  detailPayout: { width: '25%', textAlign: 'right' },
  total: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 15,
    paddingTop: 15,
    borderTopWidth: 2,
    borderTopColor: '#000',
    position: 'relative',
  },
  totalDoubleUnderline: {
    position: 'absolute',
    bottom: -5,
    right: 0,
    width: '25%',
    padding: 1,
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },

  // And same for subtotal
  subtotalRow: {
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
    paddingTop: 10,
    position: 'relative',
  },
  subtotalDoubleUnderline: {
    position: 'absolute',
    bottom: -5,
    right: 0,
    width: '25%',
    padding: 1,
    borderTopWidth: 1,
    borderBottomColor: '#000',
  },
});

const PageHeader = () => (
  <View style={styles.headerLogoContainer}>
    <Image style={styles.headerImage} src={HeaderImage} />
  </View>
);

const PageFooter = () => (
  <View style={styles.footerContainer}>
    <View>
      <Text style={styles.footerText}>MY SPONSOR AG</Text>
      <Text style={styles.footerText}>Kemptpark 30</Text>
      <Text style={styles.footerText}>8310 Kemptthal</Text>
    </View>
    <View style={styles.footerRight}>
      <Text style={styles.footerTextRight}>+41 41 790 90 90</Text>
      <Text style={styles.footerTextRight}>info@mysponsor.ch</Text>
      <Text style={styles.footerTextRight}>www.mysponsor.ch</Text>
    </View>
  </View>
);

const SummaryPage = ({ payout, toDate }) => {
  const transactions = [...payout.transactionDetails];
  transactions.sort((a, b) => new Date(a.date) - new Date(b.date));
  const fromDate = transactions[0]?.date;

  const productGroups = transactions.reduce((acc, tx) => {
    if (!acc[tx.productName]) {
      acc[tx.productName] = {
        count: 0,
        total: 0,
        payout: 0,
      };
    }
    acc[tx.productName].count++;
    acc[tx.productName].total += tx.amount;
    acc[tx.productName].payout += (tx.amount - tx.commissionAmount);
    return acc;
  }, {});

  const totalPayout = Object.values(productGroups).reduce((sum, group) => sum + group.payout, 0);

  return (
    <Page size="A4" style={styles.page}>
      <PageHeader />
      <View style={styles.header}>
        <View style={styles.addressBlock}>
          <View style={styles.address}>
            <Text style={styles.addressTitle}>Auszahlung von:</Text>
            <Text style={styles.addressText}>MY SPONSOR AG{'\n'}Kemptpark 30{'\n'}8310 Kemptthal</Text>
          </View>
          <View style={styles.addressRight}>
            <Text style={styles.addressTitleRight}>Auszahlung an:</Text>
            <Text style={styles.addressTextRight}>{payout.iban}{'\n'}{payout.clubName}{'\n'}{payout.address?.address1}{'\n'}{payout.address?.postalCode} {payout.address?.city}</Text>
          </View>
        </View>
      </View>

      <View style={styles.mainContent}>
        <Text style={styles.title}>Auszahlung vom {moment().format('DD.MM.YYYY')}</Text>
        <Text style={styles.dateRange}>Zeitraum: {moment(fromDate).format('DD.MM.YYYY')} - {moment(toDate).format('DD.MM.YYYY')}</Text>

        <View style={styles.summaryTable}>
          <View style={styles.tableHeader}>
            <Text style={styles.col1}>Produkt</Text>
            <Text style={styles.col2}>Anzahl</Text>
            <Text style={styles.col3}>Total</Text>
            <Text style={styles.col4}>Auszahlungsbetrag</Text>
          </View>

          {Object.entries(productGroups).map(([name, data]) => (
            <View style={styles.tableRow} key={name}>
              <Text style={styles.col1}>{name}</Text>
              <Text style={styles.col2}>{data.count}</Text>
              <Text style={styles.col3}>{formatNumber(data.total)}</Text>
              <Text style={styles.col4}>{formatNumber(data.payout)}</Text>
            </View>
          ))}
        </View>

        <View style={styles.total}>
          <Text style={styles.totalLabel}>Totalbetrag:</Text>
          <Text style={styles.totalAmount}>CHF {formatNumber(totalPayout)}</Text>
          <View style={styles.totalDoubleUnderline} />
        </View>
      </View>
      <PageFooter />
    </Page>
  );
};

const DetailPage = ({ transactions, productName, pageNumber, totalPages }) => {
  const subtotal = transactions.reduce((sum, tx) => sum + (tx.amount - tx.commissionAmount), 0);

  return (
    <Page size="A4" style={styles.detailPage}>
      <PageHeader />
      <View style={styles.productTitle}>
        <Text>{productName} - Seite {pageNumber} von {totalPages}</Text>
      </View>

      <View style={styles.detailTableHeader}>
        <Text style={styles.detailName}>Name</Text>
        <Text style={styles.detailId}>ID</Text>
        <Text style={styles.detailAmount}>Betrag</Text>
        <Text style={styles.detailCommission}>Kommission</Text>
        <Text style={styles.detailPayout}>Auszahlungsbetrag</Text>
      </View>

      {transactions.map((tx) => (
        <View style={styles.detailTableRow} key={tx.id}>
          <Text style={styles.detailName}>{tx.userName}</Text>
          <Text style={styles.detailId}>{tx.id}</Text>
          <Text style={styles.detailAmount}>{formatNumber(tx.amount)}</Text>
          <Text style={styles.detailCommission}>{formatNumber(tx.commissionAmount)}</Text>
          <Text style={styles.detailPayout}>{formatNumber(tx.amount - tx.commissionAmount)}</Text>
        </View>
      ))}

      <View style={styles.subtotalRow}>
        <Text style={styles.totalLabel}>Zwischentotal:</Text>
        <Text style={styles.totalAmount}>CHF {formatNumber(subtotal)}</Text>
        <View style={styles.subtotalDoubleUnderline} />
      </View>

      <PageFooter />
    </Page>
  );
};

const PayoutDocument = ({ payout, to }) => {
  const productGroups = payout.transactionDetails.reduce((acc, tx) => {
    if (!acc[tx.productName]) {
      acc[tx.productName] = [];
    }
    acc[tx.productName].push(tx);
    return acc;
  }, {});

  const ITEMS_PER_PAGE = 20;
  const productPages = Object.entries(productGroups).map(([name, transactions]) => {
    const pages = [];
    for (let i = 0; i < transactions.length; i += ITEMS_PER_PAGE) {
      pages.push(transactions.slice(i, i + ITEMS_PER_PAGE));
    }
    return { name, pages };
  });

  return (
    <Document>
      <SummaryPage payout={payout} toDate={to} />
      {productPages.map(({ name, pages }) =>
        pages.map((transactions, idx) => (
          <DetailPage
            key={`${name}-${idx}`}
            transactions={transactions}
            productName={name}
            pageNumber={idx + 1}
            totalPages={pages.length}
          />
        ))
      )}
    </Document>
  );
};

PayoutDocument.propTypes = {
  payout: PropTypes.shape({
    clubName: PropTypes.string.isRequired,
    iban: PropTypes.string.isRequired,
    address: PropTypes.shape({
      address1: PropTypes.string,
      postalCode: PropTypes.string,
      city: PropTypes.string,
    }),
    transactionDetails: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number,
      userName: PropTypes.string,
      amount: PropTypes.number,
      commissionAmount: PropTypes.number,
      productName: PropTypes.string,
      date: PropTypes.string,
    })).isRequired,
  }).isRequired,
  to: PropTypes.string.isRequired,
};

export default PayoutDocument;