import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PayoutPDFDocument from 'src/components/PayoutPdfDocument';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  makeStyles,
  Button
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  },
  viewBtn: {
    textDecoration: 'none',
  }
}));

const Results = ({ className, payouts, ...rest }) => {
  const classes = useStyles();
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const getPayoutFileName = (payout, toDate) => {
    // Sort transactions by date and get oldest
    const transactions = [...payout.transactionDetails];
    transactions.sort((a, b) => new Date(a.date) - new Date(b.date));
    const oldestTransaction = transactions[0];

    if (!oldestTransaction) return `Payout_${payout.clubName.replace(/ /g, '_')}.pdf`;

    const oldestDate = new Date(oldestTransaction.date).toLocaleDateString('de-DE');
    const toDateFormatted = new Date(toDate).toLocaleDateString('de-DE');
    const sanitizedClubName = payout.clubName.replace(/ /g, '_');
    return `Payout_${oldestDate}-${toDateFormatted}_${sanitizedClubName}.pdf`;
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <PerfectScrollbar>
        <Box minWidth={1050}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Club</TableCell>
                <TableCell>Anzahl der Spenden</TableCell>
                <TableCell>Betrag</TableCell>
                <TableCell>Kommission</TableCell>
                <TableCell>Auszahlungsbetrag</TableCell>
                <TableCell>Auszahlung-PDF</TableCell>
                <TableCell className="last-col">Aktion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {payouts && payouts.slice(0, limit).map((payout) => (
                <TableRow hover key={payout.clubId}>
                  <TableCell>
                    <Box alignItems="center" display="flex">
                      <Typography color="textPrimary" variant="body1">
                        {payout.clubName}
                      </Typography>
                    </Box>
                  </TableCell>
                  <TableCell>{payout.noOfDonations}</TableCell>
                  <TableCell>{payout.amount}</TableCell>
                  <TableCell>{payout.commissionAmount}</TableCell>
                  <TableCell>{rest.calculatePayoutAmount(payout)}</TableCell>
                  <TableCell>
                    <PDFDownloadLink
                      document={<PayoutPDFDocument payout={payout} to={rest.to} />}
                      fileName={getPayoutFileName(payout, rest.to)}
                    >
                      {({ loading }) => (loading ? 'Loading document...' : 'Download PDF')}
                    </PDFDownloadLink>
                  </TableCell>
                  <TableCell className="last-col">
                    <Button
                      className="action-btn"
                      onClick={() => rest.handlePayout(payout)}
                      disabled={rest.isButtonClicked}
                      color="primary"
                    >
                      Payout
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
      <TablePagination
        component="div"
        count={payouts.length}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  payouts: PropTypes.array.isRequired
};

export default Results;