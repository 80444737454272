import React, { useEffect, useState } from 'react';
import { Container } from '@material-ui/core';
import Page from 'src/components/Page';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getAllPendingPayouts, clubPayout } from 'src/actions/donationAction';
import uploadFiles from 'src/actions/fileUploadAction';
import ConfirmModal from 'src/components/ConfirmModal';
import moment from 'moment';
import PayoutDocument from 'src/components/PayoutPdfDocument';
import { pdf } from '@react-pdf/renderer';
import Results from './Results';
import Toolbar from './Toolbar';
import PayoutCSVFile from './PayoutCSVFile';
import PayoutLogs from './PayoutLogs';

const styles = {
  root: {
    backgroundColor: '#f4f6f8',
    minHeight: '100vh',
  },
  contentWrapper: {
    backgroundColor: '#fff',
    borderRadius: '8px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12)',
    padding: '24px',
    marginTop: '24px'
  },
  dateContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '16px',
    marginBottom: '24px'
  },
  datePicker: {
    padding: '8px 12px',
    border: '1px solid #e0e0e0',
    borderRadius: '4px',
    width: '200px',
    fontSize: '14px'
  },
  title: {
    fontWeight: 500,
    color: '#3a3a3a',
    marginBottom: '16px',
  },
  label: {
    fontSize: '14px',
    fontWeight: 500,
    color: '#3a3a3a',
    marginBottom: '4px'
  },
  dateGroup: {
    display: 'flex',
    flexDirection: 'column'
  },
  error: {
    color: '#d32f2f',
    fontSize: '12px',
    marginTop: '4px'
  }
};

const DonationListView = ({ pendingPayouts, getAllPendingPayouts: fetchAllPayouts, clubPayout: savePayout, uploadFiles: fileUpload }) => {
  const [loading, setLoading] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [to, setToDate] = useState('');
  const [toError, setToDateError] = useState('');
  const [payoutData, setPayoutData] = useState({});

  // Hardcoded from date - January 1, 1970
  const FROM_DATE = '01/01/1970';
  const currentDate = moment().format('YYYY-MM-DD');

  useEffect(() => {
    if (to) {
      fetchAllPayouts({
        fromDate: FROM_DATE,
        toDate: moment(to).format('DD/MM/YYYY')
      });
    }
  }, [fetchAllPayouts, to]);

  const handleToDateChange = (e) => {
    setToDateError('');
    setToDate(e.target.value);
  };

  const handlePayout = (data) => {
    setPayoutData(data);
    setOpenConfirmModal(true);
  };

  const handleCloseModal = () => {
    setPayoutData({});
    setOpenConfirmModal(false);
  };

  const calculatePayoutAmount = (data) => {
    if (data.amount && data.commissionAmount) {
      return parseFloat((data.amount) - (data.commissionAmount)).toFixed(2);
    }
    return 0;
  };

  const getDonationIds = (data) => {
    return data.transactionDetails?.map(detail => detail.id) || [];
  };

  const uploadDocument = async (payoutId) => {
    const toDate = moment(to).format('DD/MM/YYYY');
    const blob = await pdf(<PayoutDocument payout={payoutData} from={FROM_DATE} to={toDate} />).toBlob();
    const file = new File([blob], 'payout.pdf', {
      lastModified: new Date().getTime(),
      type: blob.type
    });
    const formData = new FormData();
    formData.append('file', file);
    formData.append('entity', 'payout');
    formData.append('column', 'pdf');
    formData.append('id', payoutId);
    fileUpload(formData);
  };

  const confirmPayout = async () => {
    setLoading(true);
    savePayout({
      paymentAmount: calculatePayoutAmount(payoutData),
      clubId: payoutData.clubId,
      donationId: getDonationIds(payoutData),
      fromDate: FROM_DATE,
      toDate: moment(to).format('DD/MM/YYYY')
    }, async (res) => {
      await uploadDocument(res.payoutId);
      setLoading(false);
      setOpenConfirmModal(false);
      setPayoutData({});
    });
  };

  return (
    <Page style={styles.root} title="Pending Payouts">
      <Container maxWidth={false}>
        <Toolbar />
        <div style={styles.contentWrapper}>
          <div style={styles.dateContainer}>
            <div style={styles.dateGroup}>
              <h2 style={styles.title}>Auszahlungen</h2>
              <label style={styles.label}>Datum bis:</label>
              <input
                type="date"
                style={styles.datePicker}
                max={currentDate}
                value={to}
                onChange={handleToDateChange}
              />
              {toError && <span style={styles.error}>{toError}</span>}
            </div>
          </div>
          <Results
            payouts={pendingPayouts}
            calculatePayoutAmount={calculatePayoutAmount}
            handlePayout={handlePayout}
            isButtonClicked={loading}
            to={to}
          />
        </div>
        <PayoutCSVFile />
        <PayoutLogs />
      </Container>
      <ConfirmModal
        open={openConfirmModal}
        handleConfirm={confirmPayout}
        handleClose={handleCloseModal}
      />
    </Page>
  );
};

DonationListView.propTypes = {
  getAllPendingPayouts: PropTypes.func.isRequired,
  uploadFiles: PropTypes.func.isRequired,
  clubPayout: PropTypes.func.isRequired,
  pendingPayouts: PropTypes.arrayOf(PropTypes.shape({})),
};

DonationListView.defaultProps = {
  pendingPayouts: [],
};

export default connect(
  state => ({ pendingPayouts: state.donation.pendingPayouts }),
  { getAllPendingPayouts, clubPayout, uploadFiles }
)(DonationListView);