import React, { useState } from 'react';
import {
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

// Add this component inside your ClubView file
const DeactivationModal = ({ open, onClose, onConfirm, clubName }) => {
  const [reason, setReason] = useState('');
  const [sendEmail, setSendEmail] = useState(false);

  const handleConfirm = () => {
    onConfirm(reason, sendEmail);
    setReason('');
    setSendEmail(true);
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Club deaktivieren</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Grund für die Deaktivierung"
          fullWidth
          multiline
          rows={4}
          value={reason}
          onChange={(e) => setReason(e.target.value)}
          variant="outlined"
          disabled={!sendEmail}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={sendEmail}
              onChange={(e) => setSendEmail(e.target.checked)}
              color="primary"
            />
          }
          label="Email an Club senden"
          style={{ marginTop: '16px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Abbrechen
        </Button>
        <Button
          onClick={handleConfirm}
          color="primary"
          variant="contained"
        >
          Bestätigen
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeactivationModal;