import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Typography,
  Tabs,
  Tab
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getAllNtApprovedClubs, getAllClubs, getAllBlockedClubs } from 'src/actions/clubActions';
import Page from 'src/components/Page';
import Results from './Results';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ClubListView = (props) => {
  const classes = useStyles();
  const [tab, setTab] = useState(1);
  const [incomplete, setIncomplete] = useState([]);
  const [complete, setComplete] = useState([]);
  const [approved, setApproved] = useState([]);

  const {
    clubs,
    approvedClubs,
    blockedClubs,
    approvedClubCount,
    getAllNtApprovedClubs: fetchNtApprovedClubs,
    getAllClubs: fetchAllClubs,
    getAllBlockedClubs: fetchBlockedClubs
  } = props;

  useEffect(() => {
    fetchNtApprovedClubs();
    fetchAllClubs({
      pageId: 0,
      pageSize: 100
    });
    fetchBlockedClubs();
  }, [fetchNtApprovedClubs, fetchAllClubs, fetchBlockedClubs]);

  useEffect(() => {
    const completeClubs = clubs.filter(club => club.completed === true);
    const incompleteClubs = clubs.filter(club => club.completed !== true);
    setComplete(completeClubs);
    setIncomplete(incompleteClubs);
  }, [clubs]);

  useEffect(() => {
    const trulyApprovedClubs = approvedClubs.filter(club => club.status === true);
    setApproved(trulyApprovedClubs);
  }, [approvedClubs]);

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  };

  return (
    <Page
      className={classes.root}
      title="Clubs"
    >
      <Container maxWidth={false}>
        <Tabs value={tab} onChange={handleTabChange}>
          <Tab label="Unvollständig" />
          <Tab label="Ausstehend" />
          <Tab label="Geprüft" />
          <Tab label="Deaktiviert" />
        </Tabs>

        {tab === 0 && (
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              Unvollständig
            </Typography>
            <Results clubs={incomplete} />
          </Box>
        )}

        {tab === 1 && (
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              Ausstehend
            </Typography>
            <Results clubs={complete} />
          </Box>
        )}

        {tab === 2 && (
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              Geprüft
            </Typography>
            <Results clubs={approved} />
          </Box>
        )}

        {tab === 3 && (
          <Box mt={3}>
            <Typography variant="h4" gutterBottom>
              Deaktiviert
            </Typography>
            <Results clubs={blockedClubs} />
          </Box>
        )}
      </Container>
    </Page>
  );
};

const mapStateToProps = (state) => ({
  clubs: state.club.notVerifiedclubs,
  approvedClubs: state.club.approvedClubs,
  blockedClubs: state.club.blockedClubs || [],
  approvedClubCount: state.club.approvedClubCount
});

const mapDispatchToProps = {
  getAllNtApprovedClubs,
  getAllClubs,
  getAllBlockedClubs
};

ClubListView.propTypes = {
  getAllNtApprovedClubs: PropTypes.func.isRequired,
  getAllClubs: PropTypes.func.isRequired,
  getAllBlockedClubs: PropTypes.func.isRequired,
  clubs: PropTypes.arrayOf(PropTypes.shape({})),
  approvedClubs: PropTypes.arrayOf(PropTypes.shape({})),
  blockedClubs: PropTypes.arrayOf(PropTypes.shape({})),
  approvedClubCount: PropTypes.number
};

ClubListView.defaultProps = {
  clubs: [],
  approvedClubs: [],
  blockedClubs: [],
  approvedClubCount: 0
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubListView);