import React, { useState, useEffect } from 'react';
import { useParams, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  Divider,
  Grid,
  TextField,
  Typography,
  Paper,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import {
  CheckCircle as CheckCircleIcon,
  Warning as WarningIcon,
} from '@material-ui/icons';
import {
  getAllClubDetails,
  approveClub,
  getClubPercentage,
  getClubSalesperson,
  addClubPercentage,
  updateClubPercentage,
  changeBlockStatus,
  updateClubAccountNumber
} from 'src/actions/clubActions';
import { getClubTransactions, getClubPayoutHistory } from 'src/actions/donationAction';
import { getClubProducts } from 'src/actions/productActions';
import { assignSalespersonToClub, updateClubSalesperson } from 'src/actions/salespersonActions';
import { getFileURL } from '../../transaction/TransactionDetailView';
import SalespersonSelect from './SelectSalesperson';
import TransactionsList from '../TransactionResults';
import ProductsList from '../ProductResults';
import PayoutsList from '../PayoutResults';
import PropTypes from 'prop-types';
import DeactivationModal from './ClubDeactivationModal';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    minHeight: '100%',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3)
  },
  container: {
    marginBottom: theme.spacing(3)
  },
  statusBanner: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    borderRadius: theme.shape.borderRadius,
  },
  statusIcon: {
    marginRight: theme.spacing(1)
  },
  actionButtons: {
    display: 'flex',
    gap: theme.spacing(2),
    marginBottom: theme.spacing(3)
  },
  settingsCard: {
    height: '100%'
  },
  settingsField: {
    marginBottom: theme.spacing(2)
  },
  infoCard: {
    height: '100%'
  },
  infoList: {
    listStyle: 'none',
    padding: 0,
    margin: 0
  },
  infoListItem: {
    marginBottom: theme.spacing(1)
  },
  documentList: {
    listStyle: 'none',
    padding: 0
  },
  documentLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

const InfoCard = ({ title, children }) => {
  const classes = useStyles();
  return (
    <Card className={classes.infoCard}>
      <CardHeader title={title} style={{ backgroundColor: "#fbfbfb" }} />
      <Divider />
      <CardContent>
        {children}
      </CardContent>
    </Card>
  );
};

const ClubView = (props) => {
  const classes = useStyles();
  const { id } = useParams();
  const [clubPercentage, setPercentage] = useState('');
  const [clubAccountNumber, setAccountNumber] = useState('');
  const [selectedSalespersonId, setSalespersonId] = useState(null);
  const [savedSalespersonId, setSavedSalespersonId] = useState(null);
  const [pdfDocsUrl, setPdfDocsUrl] = useState([]);
  const [pageDetails, setPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const [payoutPageDetails, setPayoutPageDetails] = useState({ pageId: 0, pageSize: 10 });
  const [productPageDetails, setProductPageDetails] = useState({ pageId: 0, pageSize: 10 });

  const [productUpdateCounter, setProductUpdateCounter] = useState(0);

  const [deactivationModalOpen, setDeactivationModalOpen] = useState(false);

  const handleDeactivationConfirm = (reason, sendEmail) => {
    try {
      props.changeBlockStatus({
        id: id,
        method: 'block',
        sendEmail,
        reason
      });
      setDeactivationModalOpen(false);
    } catch (error) {
      console.error('Error deactivating club:', error);
    }
  };

  const {
    clubDetails,
    msg,
    redirect,
    percentageData,
    clubSalesperson,
    transactions,
    clubPayouts,
    products,
    getAllClubDetails: fetchClubDetails,
    getClubPercentage: fetchClubPercentage,
    getClubSalesperson: fetchSalesperson,
    getClubTransactions: fetchClubTransaction,
    getClubPayoutHistory: fetchClubPayouts,
    getClubProducts: fetchClubProducts,
    authData,
  } = props;

  const approvedClub = clubDetails && clubDetails.club && clubDetails.club.status;

  useEffect(() => {
    fetchClubDetails({ id });
    fetchSalesperson({ clubId: id });
    fetchClubPercentage({ clubId: id });
  }, [id]);

  useEffect(() => {
    fetchClubDetails({ id });
    fetchSalesperson({ clubId: id });
    fetchClubPercentage({ clubId: id });
    setAccountNumber(fetchClubDetails.accountNumber)
  }, [id, fetchClubDetails, fetchSalesperson, fetchClubPercentage]);

  useEffect(() => {
    if (percentageData && percentageData.rate) {
      setPercentage(percentageData.rate);
    }

    // Cleanup function
    return () => {
      setPercentage('');
    };
  }, [percentageData]);

  useEffect(() => {
    if (clubSalesperson) {
      setSavedSalespersonId(clubSalesperson);
    }
  }, [clubSalesperson]);

 
  useEffect(() => {
    const getFiles = async () => {
      const newDocs = [];
      if (clubDetails.document) {
        delete clubDetails.document.id;
        Object.keys(clubDetails.document).forEach(async (docKey) => {
          if (clubDetails.document[docKey]) {
            const url = getFileURL(clubDetails.document[docKey], authData);
            newDocs.push(url);
          }
        })
        const files = await Promise.all(newDocs);
        setPdfDocsUrl(files);
      }
    };
    getFiles();
  }, [clubDetails.document]);
 
  const handleApproveClub = () => {
    const {
      approveClub: adminApproveClub,
    } = props;
    adminApproveClub({ clubId: id });
  };

  const handlePercentageChange = (e) => {
    setPercentage(e.target.value);
  };

  const handleAccountNumberChange = (e) => {
    setAccountNumber(e.target.value);
  };

  const handleSavePercentage = (e) => {
    const { addClubPercentage: addPercentage, updateClubPercentage: updatePercentage } = props;
    e.preventDefault();
    if (percentageData && percentageData.rate) {
      updatePercentage({
        clubId: id,
        rate: clubPercentage
      });
    } else {
      addPercentage({
        clubId: id,
        rate: clubPercentage
      });
    }
  };

  const handleUpdateClubAccountNumber = (e) => {
    const {  updateClubAccountNumber: updateAccountNumber } = props;
    e.preventDefault();
    updateAccountNumber({
        id: id,
        accountNumber: clubAccountNumber
      });
  };

  const handleAssignSalesperson = (e) => {
    e.preventDefault();
    const { assignSalespersonToClub: assignSalesperson } = props;
    const data = {
      salesPersonId: selectedSalespersonId,
      clubId: id
    };
    assignSalesperson(data);
  };

  const handleUpdateSalesperson = (e) => {
    e.preventDefault();
    const { updateClubSalesperson: updateSalespersonId } = props;
    if (selectedSalespersonId !== savedSalespersonId) {
      const data = {
        salesPersonId: selectedSalespersonId,
        clubId: id
      };
      updateSalespersonId(data);
    }
  };

  const handleSelectChange = (salesPersonId) => {
    if (salesPersonId) {
      setSalespersonId(salesPersonId);
    }
  };

  const handlePageChange = (e, page) => {
    e.preventDefault();
    setPageDetails({
      ...pageDetails,
      pageId: page
    });
  };

  const handlePayoutPageSizeChange = (e) => {
    setPayoutPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
  };

  const handlePayoutPageChange = (e, page) => {
    e.preventDefault();
    setPayoutPageDetails({
      ...pageDetails,
      pageId: page
    });
  };

  const handlePageSizeChange = (e) => {
    setPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
  };

  const handleDeactivate = (e) => {
    e.preventDefault();
    setDeactivationModalOpen(true);
  };

  const handleActivate = (e) => {
    e.preventDefault();
    try {
      props.changeBlockStatus({
        id: id,
        method: 'unblock'
      });
    } catch (error) {
      console.error('Error activating club:', error);
    }
  };

  const handleProductPageChange = (e, page) => {
    e.preventDefault();
    setProductPageDetails({
      ...productPageDetails,
      pageId: page
    });
  };

  const handleProductPageSizeChange = (e) => {
    setProductPageDetails({
      pageId: 0,
      pageSize: e.target.value
    });
  };

  useEffect(() => {
    fetchClubTransaction({
      pageId: pageDetails.pageId,
      pageSize: pageDetails.pageSize,
      clubId: id
    });
  }, [pageDetails]);

  useEffect(() => {
    fetchClubPayouts({
      clubId: id,
      pageId: payoutPageDetails.pageId,
      pageSize: payoutPageDetails.pageSize
    });
  }, [payoutPageDetails]);

  useEffect(() => {
    fetchClubProducts({
      clubId: id,
      pageId: productPageDetails.pageId,
      pageSize: productPageDetails.pageSize
    }, true);
  }, [productPageDetails, productUpdateCounter]);

  const handleProductUpdate = () => {
    setProductUpdateCounter(prev => prev + 1);
  };

  const isEnabled = (!clubDetails.club || !clubDetails.bank || !clubDetails.contact || !clubPercentage);

  const displayPdfDownloadBtns = () => {
    return pdfDocsUrl.map((doc, index) => <p key={index}><a href={doc} target="_blank">Download {`doc${index + 1}`}</a></p>)
  }

  if (redirect) {
    return <Navigate to={redirect} />;
  }

  const isBlocked = clubDetails?.club?.blocked;
  const canApprove = clubDetails.club && clubDetails.bank && clubDetails.contact && clubPercentage;

  return (
    <div className={classes.root}>
      <Container maxWidth={false}>
        {/* Status Banner */}
        <Paper
          className={classes.statusBanner}
          style={{
            backgroundColor: approvedClub ? '#e8f5e9' : '#fff3e0'
          }}
        >
          {approvedClub ? (
            <CheckCircleIcon className={classes.statusIcon} style={{ color: '#2e7d32' }} />
          ) : (
            <WarningIcon className={classes.statusIcon} style={{ color: '#f57c00' }} />
          )}
          <Typography variant="h6" component="h2">
            {approvedClub ? 'Club ist genehmigt und aktiv' : 'Club wartet auf Genehmigung'}
          </Typography>
        </Paper>

        {/* Action Buttons */}
        <div className={classes.actionButtons}>
          {!approvedClub && (
            <Button
              variant="contained"
              color="primary"
              disabled={!canApprove}
              onClick={handleApproveClub}
            >
              Genehmigen
            </Button>
          )}
          <Button
            variant="outlined"
            color={isBlocked ? "primary" : "secondary"}
            onClick={isBlocked ? handleActivate : handleDeactivate}
          >
            {isBlocked ? 'Aktivieren' : 'Deaktivieren'}
          </Button>
        </div>

        {msg && (
          <Alert severity="success" className={classes.container}>
            {msg}
          </Alert>
        )}

        {/* Settings Grid */}
        <Grid container spacing={3} className={classes.container}>
          <Grid item xs={12} md={4}>
            <Card className={classes.settingsCard}>
              <CardHeader title="Kommission" style={{ backgroundColor: "#fbfbfb" }} />
              <Divider />
              <CardContent>
                <TextField
                  className={classes.settingsField}
                  fullWidth
                  label="Komission %"
                  type="number"
                  value={clubPercentage}
                  onChange={handlePercentageChange}
                  variant="outlined"
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleSavePercentage}
                >
                  {percentageData?.rate ? 'aktualisieren' : 'speichern'}
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.settingsCard}>
              <CardHeader title="Kundennummer" style={{ backgroundColor: "#fbfbfb" }} />
              <Divider />
              <CardContent>
                <TextField
                  className={classes.settingsField}
                  fullWidth
                  label="Kundennr. (Topal)"
                  value={clubAccountNumber}
                  onChange={handleAccountNumberChange}
                  variant="outlined"
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={handleUpdateClubAccountNumber}
                >
                  aktualisieren
                </Button>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card className={classes.settingsCard}>
              <CardHeader title="Agent zuweisen" style={{ backgroundColor: "#fbfbfb" }} />
              <Divider />
              <CardContent>
                <SalespersonSelect
                  savedSalespersonId={savedSalespersonId}
                  handleSelectChange={handleSelectChange}
                />
                <Button
                  fullWidth
                  variant="contained"
                  color="primary"
                  onClick={savedSalespersonId ? handleUpdateSalesperson : handleAssignSalesperson}
                >
                  {savedSalespersonId ? 'aktualisieren' : 'speichern'}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        </Grid>

        {/* Club Information Grid */}
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <InfoCard title="Basis-Informationen">
              {clubDetails.club && (
                <ul className={classes.infoList}>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Name</Typography>
                    <Typography color="textSecondary">{clubDetails.club.name}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Kurzname</Typography>
                    <Typography color="textSecondary">{clubDetails.club.shortName}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Beschreibung</Typography>
                    <Typography color="textSecondary">{clubDetails.club.description}</Typography>
                  </li>
                  {clubDetails.club.address && (
                    <li className={classes.infoListItem}>
                      <Typography variant="subtitle2">Adresse</Typography>
                      <Typography color="textSecondary">
                        {clubDetails.club.address.address1}
                        {clubDetails.club.address.address2 && <br />}
                        {clubDetails.club.address.address2}
                        <br />
                        {clubDetails.club.address.postalCode} {clubDetails.club.address.city}
                        <br />
                        {clubDetails.club.address.country}
                      </Typography>
                    </li>
                  )}
                </ul>
              )}
            </InfoCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard title="Kontaktperson">
              {clubDetails.contact && (
                <ul className={classes.infoList}>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Name</Typography>
                    <Typography color="textSecondary">
                      {`${clubDetails.contact.firstName} ${clubDetails.contact.lastName}`}
                    </Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Position</Typography>
                    <Typography color="textSecondary">{clubDetails.contact.position}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Email</Typography>
                    <Typography color="textSecondary">{clubDetails.contact.email}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Telefon</Typography>
                    <Typography color="textSecondary">{clubDetails.contact.contactNumber}</Typography>
                  </li>
                </ul>
              )}
            </InfoCard>
          </Grid>

          <Grid item xs={12} md={4}>
            <InfoCard title="Bankangaben">
              {clubDetails.bank && (
                <ul className={classes.infoList}>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Kontoname</Typography>
                    <Typography color="textSecondary">{clubDetails.bank.name}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">IBAN</Typography>
                    <Typography color="textSecondary">{clubDetails.bank.iban}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Währung</Typography>
                    <Typography color="textSecondary">{clubDetails.bank.currency}</Typography>
                  </li>
                  <li className={classes.infoListItem}>
                    <Typography variant="subtitle2">Land</Typography>
                    <Typography color="textSecondary">{clubDetails.bank.country}</Typography>
                  </li>
                </ul>
              )}
            </InfoCard>
          </Grid>

          {/* Documents Section */}
          <Grid item xs={12}>
            <InfoCard title="Dokumente">
              <ul className={classes.documentList}>
                {pdfDocsUrl.map((doc, index) => (
                  <li key={index}>
                    <a href={doc} target="_blank" rel="noopener noreferrer" className={classes.documentLink}>
                      Dokument {index + 1} herunterladen
                    </a>
                  </li>
                ))}
              </ul>
            </InfoCard>
          </Grid>

          {/* Transactions Section */}
          <Grid item xs={12}>
            <InfoCard title="Transaktionen">
              <TransactionsList
                transactions={transactions.content}
                pageDetails={pageDetails}
                handlePageChange={handlePageChange}
                handlePageSizeChange={handlePageSizeChange}
                totalCount={transactions.totalElements}
                totalPages={transactions.totalPages}
              />
            </InfoCard>
          </Grid>

          {/* Products Section */}
          <Grid item xs={12}>
            <InfoCard title="Produkte">
              <ProductsList
                products={Array.isArray(products) ? products : products.content}
                pageDetails={productPageDetails}
                handlePageChange={handleProductPageChange}
                handlePageSizeChange={handleProductPageSizeChange}
                onProductUpdate={handleProductUpdate}
              />
            </InfoCard>
          </Grid>

          {/* Payouts Section */}
          <Grid item xs={12}>
            <InfoCard title="Auszahlungen">
              <PayoutsList
                payouts={clubPayouts.content}
                pageDetails={payoutPageDetails}
                handlePageChange={handlePayoutPageChange}
                handlePageSizeChange={handlePayoutPageSizeChange}
                totalCount={clubPayouts.totalElements}
                totalPages={clubPayouts.totalPages}
              />
            </InfoCard>
          </Grid>
        </Grid>
        <DeactivationModal
          open={deactivationModalOpen}
          onClose={() => setDeactivationModalOpen(false)}
          onConfirm={handleDeactivationConfirm}
          clubName={clubDetails?.club?.name || ''}
        />
      </Container>
    </div>
  );
};

const mapStateToProps = (state) => ({
  clubDetails: state.club.clubDetails,
  redirect: state.formResponse.redirect,
  msg: state.formResponse.msg,
  percentageData: state.club.percentageData,
  clubSalesperson: state.club.clubSalesperson,
  transactions: state.donation.transactions,
  clubPayouts: state.donation.clubPayouts,
  authData: state.user.authUserData,
  products: state.club.products,
});

const mapDispatchToProps = {
  getAllClubDetails,
  getClubPercentage,
  approveClub,
  addClubPercentage,
  updateClubPercentage,
  assignSalespersonToClub,
  getClubSalesperson,
  updateClubSalesperson,
  getClubTransactions,
  getClubPayoutHistory,
  changeBlockStatus,
  updateClubAccountNumber,
  getClubProducts,
};

ClubView.propTypes = {
  getAllClubDetails: PropTypes.func.isRequired,
  getClubPercentage: PropTypes.func.isRequired,
  approveClub: PropTypes.func.isRequired,
  addClubPercentage: PropTypes.func.isRequired,
  updateClubPercentage: PropTypes.func.isRequired,
  assignSalespersonToClub: PropTypes.func.isRequired,
  updateClubSalesperson: PropTypes.func.isRequired,
  getClubSalesperson: PropTypes.func.isRequired,
  getClubPayoutHistory: PropTypes.func.isRequired,
  updateClubAccountNumber: PropTypes.func.isRequired,
  clubDetails: PropTypes.shape({
    club: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      shortName: PropTypes.string,
      description: PropTypes.string,
      email: PropTypes.string,
      logo: PropTypes.string,
      cover: PropTypes.string,
      status: PropTypes.bool,
      accountNumber: PropTypes.string,
      address: PropTypes.shape({
        id: PropTypes.number,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
      website: PropTypes.string,
      tags: PropTypes.arrayOf(PropTypes.shape({})),
    }),
    contact: PropTypes.shape({
      id: PropTypes.number,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      position: PropTypes.string,
      clubId: PropTypes.number,
      address: PropTypes.shape({
        id: PropTypes.number,
        address1: PropTypes.string,
        address2: PropTypes.string,
        postalCode: PropTypes.string,
        city: PropTypes.string,
        country: PropTypes.string,
      }),
    }),
    bank: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      iban: PropTypes.number,
      currency: PropTypes.string,
      country: PropTypes.string,
      clubId: PropTypes.number,
    }),
  }),
  msg: PropTypes.string,
  redirect: PropTypes.string,
  percentageData: PropTypes.shape({
    id: PropTypes.number,
    clubId: PropTypes.number,
    rate: PropTypes.number
  }),
  clubSalesperson: PropTypes.number,
  getClubTransactions: PropTypes.func.isRequired,
  transactions: PropTypes.array,
  clubPayouts: PropTypes.array,
  getClubProducts: PropTypes.func.isRequired,
  products: PropTypes.shape({
    content: PropTypes.array,
    totalElements: PropTypes.number,
    totalPages: PropTypes.number,
  }),
};

ClubView.defaultProps = {
  clubDetails: {},
  percentageData: {},
  msg: null,
  redirect: null,
  clubSalesperson: null,
  transactions: [],
  clubPayouts: [],
  products: {
    content: [],
    totalElements: 0,
    totalPages: 0,
  },
};

export default connect(mapStateToProps, mapDispatchToProps)(ClubView);
